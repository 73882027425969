import React from 'react';

function NotFound(props) {
    return (
        <div>
            <h2>Trang 404</h2>
        </div>
    );
}

export default NotFound;