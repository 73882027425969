import React from 'react';
import LayoutDashboard from '../../layouts/LayoutDashboard';
import {Container, Typography} from 'lvq';

function Dashboard() {
    return (
        <Container>
            <Typography tag='h1'>Trang quản lý</Typography>
        </Container>
    );
}

export default Dashboard;