import axios from "axios";
import {jwtDecode} from "jwt-decode";
import axiosClient from "../../utils/axiosClient";
import { toast } from "react-toastify";
const baseURL = "http://localhost:8080";

axios.defaults.withCredentials = true;

export const login = async (data) => {
    try {
        const response = await axios.post(`${baseURL}/api/auth/authenticate`, data)
        console.log(response.data);
        return response.data;
    } catch (error) {
        toast.error(error.response.data.message);
        return error;
    }
}

export const register = async (userData) => {
    try{
        const response = await axios.post(`${baseURL}/api/auth/register`, userData)
        return response.data;
    }catch(error){
        throw error.response.data.errors;
    }
}

export const getYourProfile = async (token) => {
    try{
        const response = await axiosClient.get(`get-profile`);
        console.log(response.data)
        return response.data;
    }catch(err){
        console.log(err);
    }
}

export const updatePasswordUser = async (userData, token) => {
    try{
        const response = await axiosClient.put(`update-password`, userData);
        console.log(response)
        return response.data;
    }catch(err){
        console.log(err)
        err.message = "Cập nhật mật khẩu thất bại!"
        throw err;
    }
}

/**AUTHENTICATION CHECKER */
export const logout = async() => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        await axiosClient.post(`logout?userId=${user.userId}`);
    } catch (e) {
        console.log(e)
    }
    localStorage.removeItem('user')
}

export const isAuthenticated = () =>{
    const user = JSON.parse(localStorage.getItem("user"));
    const token = user?.token;
    return !!token;
}

export const isAdmin = () =>{
    const user = JSON.parse(localStorage.getItem("user"));
    const token = user?.token;
    const decodedToken = jwtDecode(token);
    return decodedToken.roles === 'ROLE_ADMIN';
}

export const isAssistant= () =>{
    const user = JSON.parse(localStorage.getItem("user"));
    const token = user?.token;
    const decodedToken = jwtDecode(token);
    return decodedToken.roles === 'ROLE_ASSISTANT';
}

export const isListener = () =>{
    const user = JSON.parse(localStorage.getItem("user"));
    const token = user?.token;
    const decodedToken = jwtDecode(token);
    return decodedToken.roles === 'ROLE_LISTENER';
}

export const adminOnly = () =>{
    return this.isAuthenticated() && this.isAdmin();
}