import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Flex, Group, Input, Pagination, Table, Typography } from 'lvq';
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import * as UserService from "../../../core/services/UserService";

function UserList() {

    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = 100; // Tổng số trang

    useEffect(() => {

        const fetchProducts = async () => {
            await getUsersList();
        }
        fetchProducts().then().catch(console.error);
    }, []);

    const getUsersList = async () => {
        const temp = await UserService.getAlllUser();
        setUsers(temp.content);
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
        console.log(`Đã chọn trang: ${page}`); // Xử lý số trang được chọn (ví dụ: log ra console)
    };

    const columns = [
        {
            key: 'name',
            header: 'Tên người dùng',
            render: (row) => row.fullName,
        },
    ];

    return (
        <Container>
            <Flex justifyContent='between'>
                <Typography tag="h1">Danh sách người dùng</Typography>
                <Button text='Thêm mới' onClick={() => navigate("/dashboard/song-create")} />
            </Flex>
            <Group className=''>
                <Flex>
                    <Input type="text" gd={{ maxWidth: "400px" }} placeholder='Tìm kiếm người dùng ...' />
                    <Button text='Tìm kiếm' />
                </Flex>
                <Table border={false} columns={columns} data={users} rowKey={"id"} gd={{ borderRadius: '10px' }} />
                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
            </Group>
        </Container>
    );
}

export default UserList;